.App {
  display: flex;
  text-align: center;
}
html {
  scroll-behavior: smooth;
}

/*body {
  box-sizing: border-box;
  overflow-x: hidden;
}
.anim {
  width: 100vw;
  height: 100vh;
  background-color: black;
}*/

.bg-myColor {
  background-image: linear-gradient(to right,#f3f2f2e4,#f9f4f4aa);
  background-blend-mode: overlay;
  
  }
  
  .d-inline-block.align-top {
    height: 42px; 
    margin: 10px; 
  }


.App-header {
  position: sticky;
  top: 0px;
  background-color: #050505;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header.down {
  top: -6rem;
}
.App-link {
  color: #61dafb;
}

.bg-dark {
  color: rgb(250, 252, 250);
  background-color: rgb(255, 255, 255) !important;
}
.text-black :hover {
  color: rgb(255, 255, 255) !important;
}

@media (max-width: 992px) {
  .bg-myColor {
    background-image: linear-gradient(to right,#f4f2f2eb,#f9f4f4aa);
    /*background-color: #f9f4f4b0;*/
    background-blend-mode: overlay;
    }
    .d-inline-block.align-top {
      height: 50px; 
      margin: 0px; 
      padding-block-end: 16px;
    }
  .App {
    flex-direction: column; 
  }
  .nav-link {
    font-size: 16px; 
  }
  .navbar-collapse {
    background-color: whitesmoke;
  }
  
}


.navbar-collapse {
  z-index: 1;
  justify-content: flex-end;
}
.navbar-toggler {
  background-color: rgba(18, 19, 18, 0.957); 
  margin-bottom: 25px;
  padding-block-end: 15px;
  padding: 5px;
  
}

.collapsed {
  margin-right: 20px;
}
.navbar-brand {
  margin-left: 20px;
}

.nav-link {
  size: large;
  padding: 20px 50px !important;
  position: relative;
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  transition: color 0.3s ease;
}
.navbar {
  z-index: 1000;
  position: absolute;
  top: 0;
  width: 100%;
  height: 10.85vh;
  transition: top 0.3s ease-in-out;
 
}
.hidden {
  top: -100px;
}

.nav-link:hover {
  color: #17b832;
}

.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(40, 247, 8);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease;
}

.nav-link:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.section {
  padding: 10px 20px; /* Add padding around each section */
  max-height: 100%;
}

.page-container {
  margin-top: 10.85vh; /* Account for navbar height */
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.page-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}



