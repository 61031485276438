.products-grid{   
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.product-card {
  min-width: 30vw;
  width: 30vw;
  min-height: 40vh;
  height: 60vh;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
}

.image-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.product-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 5%;
}

.product-name {
  margin-left: 20px;
}

.details {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.button-container {
  margin-top: auto;
  text-align: center;
}

.modal {
  position: fixed;
  top: 5vh;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  color: black;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 50vw;
  height: 80vh;
}

.modal-content button {
  margin-top: 10px;
}
