.page2-container {
 
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}
h1 {
  text-align: center;
} 
.container2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section2 {
  flex: 1;
  margin: 0 10px;
  
}

.section2 h2 {
  text-align: center;
}

.section2 p {
  text-align: justify;
}

.imageclass2 {
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: column;
}

.img3 {
  width: 600px;
  height: 400px;
  object-fit: fill;
  border-radius: 5%;
}

.textsection1 {
  min-width: 30vw;
  width: 30vw;
  min-height: 40vh;
  height: 60vh;
  display: flex;
  flex-direction: column;
  margin: 50px;
  padding: 10px;
}

.textsection1 p1 {
  text-align: left; 
}

.textsection1 h1 {
  text-align: left; 
  color: #0bf913;
}

.button {
  background-color: #0bf913; /* Green */
  border: 10px; /* Consider removing or adjusting border */
  color: rgb(3, 3, 3);
  padding: 15px 32px;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1 {
  background-color: #0bf913;
  color: black;
}

.button1:hover {
  background-color: rgb(7, 7, 7);
  color: #0bf913;
}

@media only screen and (max-width: 992px) {

  .page2-container {
    padding: 10px;
  }
  .container2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .imageclass2 {
    flex-direction: row; 
  }

  .img3 {
    width: 100%; 
    height: auto; 
  }

  
  .textsection1 {
    min-width: unset; 
    width: 100%; 
    min-height: unset; 
    margin: 10px 0; 
     
  }

  .textsection1 p,
  .textsection1 h1 {
    text-align: justify;
  }
  
  .textsection1 h1 {
    font-size: 1.0rem; 
  }
  .textsection1 h6 {
    font-size: 0.7rem;
  }

  
  .button {
    font-size: 12px; 
    padding: 10px 20px; 
  }
}
