.about-us-container {
 
  max-width: 100%;
  max-height: max-content;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color:#0bf913;
  text-align: center;
}

.imageclass {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.img1 {
  width: 300px;
  height: 250px;
  opacity: 80%;
  object-fit: fill;
  border-radius: 2%;
  padding-bottom: 5%;
}

.info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-section {
  flex: 1;
  margin: 0 10px;
}

.info-section h2 {
  text-align: center;
}

.info-section p {
  text-align: justify;
}

@media only screen and (max-width: 992px) {

  
  .about-us-container {
    padding: 10px; 
  }

  .info-container {
    flex-direction: row;
  }
  
  .img1 {
    width: 75%; 
    height: auto; 
    object-fit: fill;
    border-radius: 50%;
    padding-bottom: 5%;
  }

  .info-section {
    flex: initial; 
    margin: 10px 0; 
  }

 
  .info-section h2,
  .info-section p {
    text-align: justify;
  }
  .info-section h2 {
    font-size: 1.0rem;
  }
  
  .info-section p {
    font-size: 0.7rem;
  }
}
