/* Home.css */

.section {
  position: relative;
  z-index: 0;
  width: 100vw;
  /* top:10vh; */
  height: 100vh;
  background-image: url("../images/pexels-francesco-ungaro-998641.jpg");
  background-size: cover;
  background-position: center;
  background-color: #050404c3;
  background-blend-mode: overlay;
  color: #fff; /* White text */
  padding: 100px 0;
  text-align: center;
  align-content: center;
  overflow-x: hidden !important;
  scroll-snap-align: start;
  overflow-y: scroll; /* Show vertical scrollbar */
  scrollbar-width: none !important; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none !important;
  font-family: "Poppins", sans-serif; /* Stylish font for headings */
}

@media (max-width: 992px) {
  #testimonials::before {
    height: 84px;
  }
}

.featured-products {
  height: max-content;
}
