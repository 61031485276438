.vision-container { 
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
  }
    
  .container3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .section3 {
    flex: 1;
    margin: 0 10px;
  }
  
  .section3 h2 {
    text-align: center;
  }
  
  .section3 p {
    text-align: justify;
  }
  @media (max-width: 992px) {
    .vision-container {
      padding: 10px; 
    }
    
    .container3 {
      flex-direction: column; 
    }
  
    .section3 {
      flex: 1 0 100%; 
      margin: 10px 0;
      padding-inline-end: 30px;      
       
    }
  
    .section3 h2 {
      text-align: center; 
    }
  
    .section3 p {
      text-align: justify;
    }

    .section3 h1 {
      font-size: 1.0rem; 
    }

    .section3 h7 {
      font-size: 0.8rem; 
      
    }
  }