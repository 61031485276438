.social-media {
  display: flex;
  justify-content: space-evenly;
}
.footerSection {
  text-align: center;
  align-content: center;
  overflow-x: hidden !important;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  height: 5vh;
}
