/* Styles for testimonials section */
.section-title {
  text-align: center;
  margin-bottom: 30px;
}

.carousel-caption {
  text-align: center;
}
.carousel {
  padding-top: 10vh;
  display: flex;
  width: 100vw;
}
.carousel-indicators {
  top: 45vh;
}
