.contact-us {
  
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 100px;
}
h2{
  color:#0bf913;
}

.contact-us button {
  background-color: #080808;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.contact-us button:hover {
  background-color: #0b0b0b;
}

@media (max-width: 992px) {
  .contact-us {
    max-width: 90%; 
  }

  h2 {
   
    font-size: 1.0rem; 
  }

  input[type="text"],
  input[type="email"],
  textarea,label,p{
    font-size: 0.8rem;
  }

}
