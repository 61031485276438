.page1-container {
 
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  
  }

  h1 {
    text-align: center;
  } 
  
  .container1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .section1 {
    flex: 1;
    margin: 0 10px;
  }
  
  .section1 h2 {
    text-align: center;
  }
  
  .section1 p {
    text-align: justify;
  }

  .imageclass1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  
  .img2 {
    width: 450px;
    height: 400px;
    object-fit: fill;
    border-radius: 5%;
  }
  
  .textsection{
    min-width: 30vw;
    width: 30vw;
    min-height: 40vh;
    height: 60vh;
    display: flex;
    flex-direction: column;
    margin:50px;
    padding: 10px;
  }
  
  .textsection p1{
    text-align: left;
  }
  .textsection h1{
    text-align: left;
    color: #0bf913;
  }
 
  .button {
    background-color: #0bf913; /* Green */
    border:  10px;
    color: rgb(3, 3, 3);
    padding: 15px 32px;
    position: relative;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  .button1 {
    background-color: #0bf913; 
    color: black; 
   
  }
  
  .button1:hover {
    background-color: rgb(7, 7, 7);
    color: #0bf913;
  }
 
@media only screen and (max-width: 992px) {

 
  .page1-container {
    padding: 10px; 
  }
  .container1 {
    flex-direction: column;
  }

  
  .section1 p {
    text-align: justify;
  }

  
  .imageclass1 {
    flex-direction: row; 
  }

  .img2 {
    width: 100%; 
    height: auto; 
    
  }

  
  .textsection {
    min-width: unset; 
    width: 100%; 
    min-height: unset; 
    margin: 10px 0; 
     
  }

  .textsection p,
  .textsection h1 {
    text-align: justify;
  }
  
  .textsection h1 {
    font-size: 1.0rem; 
  }
  .textsection h6 {
    font-size: 0.7rem;
  }

  
  .button {
    font-size: 12px; 
    padding: 10px 20px; 
  }
}
