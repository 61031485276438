/* Banner Section */
#banner {
  background-image: url("../images/dsimg.jpg"); 
  background-size: cover;
  background-position: center; 
  background-color: #060606d6;
  background-blend-mode: multiply;
  
  color: #0bf913;
  font-family: "arial" ;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


#banner h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

#banner h1 span {
  color: #f9f4f4e5; 
  font-weight: bold;
}

#banner h4 {
  color: #f1f2f1;
  font-family: 'Courier New', Courier, monospace ;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

#banner p {
  font-size: 1.5rem;
  font-family: 'Courier New', Courier, monospace ;
  font-weight: bold;
  margin-bottom: 30px;

}
.pgbtm{
  position: absolute;
  width:100%;
  margin-left: calc(width/2);  
  bottom: 20px;
     
}
.pgbtm a{
  color:#0bf913;
}

.offers {
  display: flex;
  justify-content: space-around;
}

.offer {
  width: 30%;
  padding: 20px;
  background-color: rgba(15, 15, 15, 0.911); /* Semi-transparent white */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(48, 234, 11, 0.911); /* Shadow */
}

.offer h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.offer p {
  font-size: 1rem;
  line-height: 1.4;
}

.button1{
  position:absolute;
  bottom: 5%;
  right:20%;
} 

.page-container-divider {
  position: relative;
  top: 60vh;
  width: 100vw;
  line-height: 3;
  border: none !important;
  transform: rotate(180deg);
}

.page-container-divider svg {
  position: absolute;
  display: block;
  width: 100vw;
  height: 158px;
}

.page-container-divider .shape-fill {
  fill: #79f04e;
}
@media (max-width: 992px) {
  #banner h1 {
    font-size: 2.0rem;
    margin-bottom: 20px;
  }
  #banner p {
    font-size: 1.0rem;
    font-family: 'Courier New', Courier, monospace ;
    font-weight: bold;
    margin-bottom: 30px;
  
  }

  
  
}
